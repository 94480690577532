import { UserProfile } from 'api/account/models/UserProfile';
import { CatalogMaintenanceContractsSearchCriteria } from 'api/catalog/models/CatalogMaintenanceContractsSearchCriteria';
import { CatalogSupplierSearchCriteria } from 'api/catalog/models/CatalogSupplierSearchCriteria';
import MaintenanceContractsService from 'api/contracts/maintenanceContract/MaintenanceContractsService';
import { GenericType } from 'api/genericTypes/enums/GenericType';
import SuppliersService from 'api/suppliers/SuppliersService';
import UsersService from 'api/users/UsersService';
import { VehicleMaintenanceStates } from 'api/vehicleMaintenances/enums/VehicleMaintenanceStates';
import { VehicleMaintenanceDto } from 'api/vehicleMaintenances/models/VehicleMaintenanceDto';
import VehicleMaintenancesService from 'api/vehicleMaintenances/VehicleMaintenancesService';
import VehiclesService from 'api/vehicles/VehiclesService';
import AlertNoticesService from 'api/alertNotices/AlertNoticesService';
import Button from 'common/components/button/Button';
import FileSelector from 'common/components/fileSelector/FileSelector';
import FormItem from 'common/components/formItem/FormItem';
import InputController from 'common/components/input/InputController';
import InputResizable from 'common/components/input/InputResizable';
import InputError from 'common/components/inputError/InputError';
import InputGroup from 'common/components/inputGroup/InputGroup';
import InputGroupController from 'common/components/inputGroup/InputGroupController';
import Label from 'common/components/label/Label';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import SelectController from 'common/components/select/SelectController';
import CustomFile from 'common/models/CustomFile';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import Utils from 'common/services/Utils';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import { DATE_FORMAT_DEFAUT, DATE_FORMAT_MONTH_TEXT_YEAR, DEFAULT_INPUT_RULES_WITH_REQUIRED, LOGGER_LOG_TYPE, removeAccents } from 'Config';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaCalendarAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Reducers } from 'store/types';
import IconBack from 'assets/svg/Arrow-grey.svg';
import styles from './MaintenanceScreen.module.scss';
import InputGroupDate from 'common/components/inputGroupDate/InputGroupDate';
import DateWithHour from 'common/components/dateWithHour/DateWithHour';
import { AlertNoticeDto, SelectedAlertNoticeDto } from 'api/alertNotices/models/AlertNoticeDto';
import { AlertNoticeTypeEnum } from 'api/alertNotices/enums/AlertNoticeTypeEnum';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import AddIcon from 'assets/svg/desktop_add_new.svg';
import { ReactComponent as TrashIcon } from 'assets/svg/trash_no_color.svg';
import { FaInfoCircle } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import DateTimePickerControllerNew from 'common/components/dateTimePicker/DateTimePickerControllerNew';
import { VehicleDto } from 'api/vehicles/models/VehicleDto';
import InputInfoWarning from 'common/components/inputInfoWarning/InputInfoWarning';
import TrackingService from 'api/tracking/TrackingService';
import { TrackingProvider } from 'api/tracking/enums/TrackingProvider';


export type Props = {
    item?: VehicleMaintenanceDto | null;
    isDetails: boolean;
    onBackList: () => void;
    onItemDetails: (state: boolean) => void;
    onSubmitDone: (itemId: string) => void;
    isModal?: boolean;
    vehicleId?: string | null;
    editable: boolean;
    titleHeader: string | undefined;
} & React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const MaintenanceScreen = ({ item, editable, isDetails, onBackList, onItemDetails, onSubmitDone, titleHeader, isModal, vehicleId: propVehicleId, ...props }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const history = useHistory();
    // eslint-disable-next-line
    let { vehicleId: routeVehicleId, itemType, itemId, tabId, type } = useParams<{ vehicleId: string, itemType: string, itemId: string, tabId: string, type: string }>();
    const vehicleId = propVehicleId ? propVehicleId : routeVehicleId;
    const form = useForm<VehicleMaintenanceDto>({ shouldUnregister: false });
    const alertNotices = useFieldArray<SelectedAlertNoticeDto>({ control: form.control, name: 'alertNotices', keyName: 'formId' as any });
    const plannedAlertNotices = useFieldArray<SelectedAlertNoticeDto>({ control: form.control, name: 'plannedAlertNotices', keyName: 'formId' as any });

    const { handleSubmit, setValue, getValues, errors, reset } = form;

    const [showAlertNotice, setShowAlertNotice] = useState<boolean>(false);
    const [showPlannedAlertNotice, setShowPlannedAlertNotice] = useState<boolean>(false);
    const [vehicleOptions, setVehicleOptions] = useState<SelectValueLabel[]>([]);
    const [itemToRemove, setItemToRemove] = useState<VehicleMaintenanceDto>();
    const [dialogDeleteItemIsOpen, setDialogDeleteItemIsOpen] = React.useState(false);
    const [maintenance, setMaintenance] = useState<VehicleMaintenanceDto | null>(null);

    const [alertNoticeOptions, setAlertNoticeOptions] = useState<SelectValueLabel[]>([]);
    const [showRemoveAlertNotice, setShowRemoveAlertNotice] = useState<boolean>(false);
    const [alertNoticeToRemoveIndex, setAlertNoticeToRemoveIndex] = useState<number | null>(null);
    const [alertNoticeIdsToRemove, setAlertNoticeIdsToRemove] = useState<string[]>([]);

    const [plannedAlertNoticeOptions, setPlannedAlertNoticeOptions] = useState<SelectValueLabel[]>([]);
    const [showRemovePlannedAlertNotice, setShowRemovePlannedAlertNotice] = useState<boolean>(false);
    const [plannedAlertNoticeToRemoveIndex, setPlannedAlertNoticeToRemoveIndex] = useState<number | null>(null);
    const [plannedAlertNoticeIdsToRemove, setPlannedAlertNoticeIdsToRemove] = useState<string[]>([]);


    const [contractTypeOptions, setContractTypeOptions] = useState<SelectValueLabel[]>([]);
    const [contractNumbersOptions, setContractNumbersOptions] = useState<SelectValueLabel[]>([]);
    const [statesOptions, setStatesOptions] = useState<SelectValueLabel[]>([]);
    const [responsibleOptions, setResponsibleOptions] = useState<SelectValueLabel[]>([]);
    const [supplierOptions, setSupplierOptions] = useState<SelectValueLabel[]>([]);

    const [vehicle, setVehicle] = useState<VehicleDto | undefined>(undefined);
    const [inputKmsBelowCurrentVehicleKms, setInputKmsBelowCurrentVehicleKms] = useState<boolean>(false);

    const [state, setState] = useState<string>();

    const [savedFiles, setSavedFiles] = useState<CustomFile[]>([]);

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasVehicleMaintenancesWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_VEHICLE_MAINTENANCES_WRITE']);
    const hasReadGPSPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_GPS_READ']);
    const [currentKmsGps, setCurrentKmsGps] = useState<number | null>(null);

    const getData = async () => {
        try {
            Loading.show();
            setValue('vehicleId', vehicleId);

            const contractTypesOrig = await MaintenanceContractsService.getMaintenanceContractTypes();
            const contractTypes = contractTypesOrig.map(x => ({ ...x, label: t(`common.maintenance_contract_type.${x.label}` as any) }));
            const contractTypesOrdered = Utils.sortAlphabetically(contractTypes);
            setContractTypeOptions(contractTypesOrdered);

            const responsibles = await UsersService.catalog();
            setResponsibleOptions((responsibles || []).map((x: SelectValueLabel) => ({ value: x.value || '', label: x.label || '' })));

            const suppliers = await SuppliersService.catalog({ typeName: GenericType.MAINTENANCE } as CatalogSupplierSearchCriteria);
            setSupplierOptions(suppliers);

            const vehicleMaintenanceStates = Object.keys(VehicleMaintenanceStates).filter(key => isNaN(Number(key)));

            const states: SelectValueLabel[] = vehicleMaintenanceStates.map(vehicleMaintenanceState => { return { value: vehicleMaintenanceState, label: t(`maintenances.states.${vehicleMaintenanceState}` as any) } });
            setStatesOptions(Utils.sortAlphabetically(states));

            const alertNoticesDB: AlertNoticeDto[] = await AlertNoticesService.getAll();
            buildAndSetAlertNoticeOptions(alertNoticesDB);

            const plannedAlertNoticesDB: AlertNoticeDto[] = await AlertNoticesService.getAll();
            buildAndSetPlannedAlertNoticeOptions(plannedAlertNoticesDB);

            let vehicleIdVerified = vehicleId;
            let resultVehicleId: string | undefined = undefined;
            if (item && item.id) {
                const result = await VehicleMaintenancesService.getById(item.id);
                if (result === undefined) {
                    addToast(t('common.messages.error_load_info'), { appearance: 'error' });
                    Logger.error(LOGGER_LOG_TYPE.MAINTENANCES, `Couldn't get the maintenance with id: ${item.id}`);
                    onBackList();
                }
                resultVehicleId = result.vehicleId;
                vehicleIdVerified = !vehicleIdVerified ? (result.vehicleId ?? '') : vehicleIdVerified;
                if (!!result.adviseMaintenanceDate) {
                    getContactNumbersByDate(result.adviseMaintenanceDate, resultVehicleId);
                } else {
                    getContractsByVehicle(resultVehicleId);
                }
                setValues(result);
            }
            else {
                getContactNumbersByDate();
            }

            if (Utils.isStringNullOrEmpty(vehicleId)) {
                const vehicles = await VehiclesService.catalog(resultVehicleId);
                setVehicleOptions(vehicles);
            }

            if (!Utils.isStringNullOrEmpty(vehicleIdVerified)) {
                getVehicle(vehicleIdVerified);
            }

            setShowAlertNotice(true)
            setShowPlannedAlertNotice(true)
            Loading.hide();

        } catch (error) {

            Logger.error(LOGGER_LOG_TYPE.MAINTENANCES, `Couldn't get information to create maintenance`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    const getGpsKms = async () => {
        if (vehicle && vehicle.id && vehicle.companyTrackingProvider && vehicle?.companyTrackingProvider !== TrackingProvider.NONE && vehicle.trackingDeviceId) {
            try {
                const position = await TrackingService.getPosition(vehicle.id);
                setCurrentKmsGps(position.km ?? null);

            } catch (error) {
                Logger.error(LOGGER_LOG_TYPE.CHARGES, `Couldn't get vehicle gps kms`, error);
                addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            }
        }
    }

    const getContractsByVehicle = async (vehicleId?: string) => {
        try {
            const results = await MaintenanceContractsService.catalog({ vehicleId: !!vehicleId ? vehicleId : form.getValues('vehicleId') } as CatalogMaintenanceContractsSearchCriteria);
            setContractNumbersOptions(results);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.MAINTENANCES, `Couldn't get contracts of vehicle`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    const getVehiclesByContract = async () => {
        try {
            const results = await VehiclesService.getByMaintenanceContractId({ id: form.getValues('maintenanceContractsId') } as CatalogMaintenanceContractsSearchCriteria);
            setVehicleOptions(results);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.MAINTENANCES, `Couldn't get vehicles of contract`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    const getContactNumbersByDate = async (date?: Date, vehicleIdAux?: string) => {
        const contractNumbers = await MaintenanceContractsService.catalog({ vehicleId: !!vehicleIdAux ? vehicleIdAux : vehicleId, date } as CatalogMaintenanceContractsSearchCriteria);
        if (!contractNumbers.find(x => x.value == getValues('maintenanceContractsId'))) {
            setValue('maintenanceContractsId', null);
        }
        setContractNumbersOptions(contractNumbers);
    }

    const setValues = (values: VehicleMaintenanceDto) => {
        values.adviseMaintenanceDate = values.adviseMaintenanceDate ? new Date(values.adviseMaintenanceDate) : undefined;
        values.expectedDate = values.expectedDate ? new Date(values.expectedDate) : undefined;
        values.maintenanceDate = values.maintenanceDate ? new Date(values.maintenanceDate) : undefined;
        values.paymentDate = values.paymentDate ? new Date(values.paymentDate) : undefined;
        reset(values);

        setValuesFiles(values.attachments);
        setMaintenance(values);
    }

    const onSubmit = async (model: VehicleMaintenanceDto) => {
        try {

            const idsAlertNoticesSelected = model.alertNotices.map(an => an.id);
            const missingKilometers = !model.adviseMaintenanceKilometers && !!alertNoticeOptions.find(anOpt => anOpt.fullLabel == AlertNoticeTypeEnum.KILOMETERS && idsAlertNoticesSelected.some(id => id == anOpt.value));
            const missingDate = !model.adviseMaintenanceDate && !!alertNoticeOptions.find(anOpt => anOpt.fullLabel == AlertNoticeTypeEnum.TIME_PERIODS && idsAlertNoticesSelected.some(id => id == anOpt.value));
            if (!model.description || !model.typeId ||
                (!model.cost && !model.maintenanceContractsId && ((state == VehicleMaintenanceStates.PERFORM) || (model.state == VehicleMaintenanceStates.PERFORM))) ||
                ((state == VehicleMaintenanceStates.PREDICTED) && (missingKilometers || missingDate))
            ) {
                addToast(missingKilometers
                    ? t('maintenances.advise_km_empty_and_alerts_filled')
                    : missingDate
                        ? t('maintenances.advise_date_empty_and_alerts_filled')
                        : t('common.messages.complete_required_fields'), { appearance: 'warning' });
                return;
            }

            Loading.show();
            setShowAlertNotice(false);
            setShowPlannedAlertNotice(false);
            if (model.maintenanceContractsId) {
                model.cost = null;
            }

            prepareFilesToSubmit();

            if (model.id || maintenance?.id) {
                if (!model.id && maintenance) {
                    model.id = maintenance.id;
                }
                await VehicleMaintenancesService.update(model, model.attachments ?? []);
            } else {
                itemId = await VehicleMaintenancesService.create(model, model.attachments ?? []);
                setMaintenance(await VehicleMaintenancesService.getById(itemId));
            }


            addToast(t('common.messages.record_save_success'), { appearance: 'success' });
            Loading.hide();
            onSubmitDone(itemId);

            getData();
        } catch (error) {
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.CHARGES, `Couldn't update the maintenance with id: ${model.id}`, error);
            arrangeFilesAfterSubmit();
            Loading.hide();
        }
    }

    const showRemoveItemDialog = (_item: VehicleMaintenanceDto) => {
        setItemToRemove(_item);
        setDialogDeleteItemIsOpen(true);
    };

    const removeItem = async () => {
        setDialogDeleteItemIsOpen(false);
        if (!!itemToRemove) {
            try {
                Loading.show();
                await VehicleMaintenancesService.remove(itemToRemove);
                addToast(t('common.messages.record_delete_success'), { appearance: 'success' });
                onBackList();
                Loading.hide();
            } catch (error) {
                addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
                Loading.hide();
            }
        }
    };

    const arrangeFilesAfterSubmit = () => {
        setValuesFiles(savedFiles);
    }

    const prepareFilesToSubmit = () => {
        setValuesFiles(getValues('attachments')?.filter(x => !x.id));
    }

    const setValuesFiles = (files: CustomFile[]) => {
        setValue('attachments', files);
        setSavedFiles(files?.filter(x => x.id));
    }

    const onRemoveFile = (file: CustomFile) => {
        if (file.id) {
            const arr = [...(getValues('attachmentsToRemove') || []), file.id];
            setValue('attachmentsToRemove', arr);
        }
    }

    useEffect(() => {
        if (vehicle && !isDetails) {
            // current kms from tracking
            getGpsKms();
        }
    }, [vehicle, isDetails]);


    useEffect(() => {
        getData();
    }, []);

    const renderButtons = () => {
        return (
            <>
                <div className={styles.buttonsContainer}>
                    <Button
                        preset={'secondary'}
                        type="button"
                        onClick={() => {
                            if (!!maintenance) { reset(maintenance); }
                            onBackList()
                        }}
                        text={t('common.cancel')}
                    />
                    {isDetails && !isModal && editable && hasVehicleMaintenancesWritePolicy && maintenance?.active &&
                        <Button
                            type="button"
                            text={t('common.remove')}
                            preset={'danger'}
                            onClick={() => showRemoveItemDialog({ id: maintenance?.id } as VehicleMaintenanceDto)} />
                    }
                    {isDetails && !isModal && editable && hasVehicleMaintenancesWritePolicy && maintenance?.active &&
                        <Button
                            type={'button'}
                            text={t('common.edit')}
                            onClick={() => { onItemDetails(false) }}
                        />}
                    {(!isDetails || isModal) && hasVehicleMaintenancesWritePolicy &&
                        <Button
                            type={'submit'}
                            text={t('common.save')}
                        />}

                    <QuestionYesNo message={t('common.messages.remove_record')}
                        isVisible={dialogDeleteItemIsOpen}
                        onYes={() => removeItem()}
                        onNo={() => setDialogDeleteItemIsOpen(false)} />
                </div>
            </>
        );
    };

    const currentState = form.watch('state');

    const maintenanceContractId = form.watch('maintenanceContractsId');

    const changeVehicle = (data: SelectValueLabel) => {
        const vId = data ? data.value : null;
        setValue('vehicleId', vId);
        getVehicle(vId);
    }

    const buildAndSetAlertNoticeOptions = (alertNoticesArr: AlertNoticeDto[]) => {
        let mappedAlertNotices: SelectValueLabel[] = [];
        if (alertNoticesArr) {
            mappedAlertNotices = alertNoticesArr.map((x, i) => {
                let label: string = x.value + ' ';
                if (x.alertNoticeType == AlertNoticeTypeEnum.KILOMETERS) {
                    label = label + (x.value > 1 ? t(('maintenances.alert_notice_type.PLURALIZED_' + AlertNoticeTypeEnum.KILOMETERS) as any) : t(('maintenances.alert_notice_type.' + AlertNoticeTypeEnum.KILOMETERS) as any)) + ' ' + t(('maintenances.alert_notice_comparison.' + x.alertNoticeComparison) as any);
                }
                else if (x.alertNoticeType == AlertNoticeTypeEnum.TIME_PERIODS) {
                    label = label + t(('maintenances.periodicities.' + x.periodicity) as any) + ' ' + t(('maintenances.alert_notice_comparison.' + x.alertNoticeComparison) as any);
                }

                return { label, value: x.id, fullLabel: x.alertNoticeType }
            });
        }

        setAlertNoticeOptions(mappedAlertNotices);
    }

    const renderAlertNotice = (index: number, an: any) => {
        return (
            <SelectController
                form={form}
                name={`alertNotices[${index}].id`}
                menuPortalTarget={document.querySelector('body')}
                options={alertNoticeOptions}
                placeholder={t('maintenances.alert')}
                rules={{ required: true }}
                isDisabled={(isDetails && !isModal) || currentState === VehicleMaintenanceStates.PERFORM}
                filterOption={(itemFilter: any, input: any) => input ? removeAccents(itemFilter.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
            />);
    }

    const addAlertNotice = () => {
        alertNotices.append({
            id: null
        });
    }

    const removeAlertNotice = () => {
        if (alertNoticeToRemoveIndex === null) return;

        if (alertNotices.fields[alertNoticeToRemoveIndex]?.id) {
            setAlertNoticeIdsToRemove([...alertNoticeIdsToRemove, alertNotices.fields[alertNoticeToRemoveIndex].id!]);
        }

        alertNotices.remove(alertNoticeToRemoveIndex);

        setShowRemoveAlertNotice(false);
    }

    const setRemoveAlertNotice = (index: number) => {
        setShowRemoveAlertNotice(true);
        setAlertNoticeToRemoveIndex(index);
    }

    const buildAndSetPlannedAlertNoticeOptions = (alertNoticesArr: AlertNoticeDto[]) => {

        let mappedAlertNotices: SelectValueLabel[] = [];
        if (alertNoticesArr) {
            mappedAlertNotices = alertNoticesArr.filter(item => item.alertNoticeType == AlertNoticeTypeEnum.TIME_PERIODS).map((x, i) => {
                const label: string = x.value + ' ' + t(('maintenances.periodicities.' + x.periodicity) as any) + ' ' + t(('maintenances.alert_notice_comparison.' + x.alertNoticeComparison) as any);
                return { label, value: x.id, fullLabel: x.alertNoticeType }
            });
        }
        setPlannedAlertNoticeOptions(mappedAlertNotices);
    }

    const renderPlannedAlertNotice = (index: number, an: any) => {
        return (
            <SelectController
                form={form}
                name={`plannedAlertNotices[${index}].id`}
                menuPortalTarget={document.querySelector('body')}
                options={plannedAlertNoticeOptions}
                placeholder={t('maintenances.alert')}
                rules={{ required: true }}
                isDisabled={(isDetails && !isModal) || currentState === VehicleMaintenanceStates.PERFORM}
                filterOption={(itemFilter: any, input: any) => input ? removeAccents(itemFilter.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
            />);
    }

    const addPlannedAlertNotice = () => {
        plannedAlertNotices.append({
            id: null
        });
    }

    const removePlannedAlertNotice = () => {
        if (plannedAlertNoticeToRemoveIndex === null) return;

        if (plannedAlertNotices.fields[plannedAlertNoticeToRemoveIndex]?.id) {
            setAlertNoticeIdsToRemove([...plannedAlertNoticeIdsToRemove, plannedAlertNotices.fields[plannedAlertNoticeToRemoveIndex].id!]);
        }

        plannedAlertNotices.remove(plannedAlertNoticeToRemoveIndex);

        setShowRemovePlannedAlertNotice(false);
    }

    const setRemovePlannedAlertNotice = (index: number) => {
        setShowRemovePlannedAlertNotice(true);
        setPlannedAlertNoticeToRemoveIndex(index);
    }

    const canShowMaintenanceQuestionArea = () => {
        const stateIsPerform = currentState === VehicleMaintenanceStates.PERFORM;

        const valueToReturn = currentState && ((!maintenance && !stateIsPerform && !isDetails) ||
            (!!maintenance?.responsibleId || !!maintenance?.expectedDate) || !stateIsPerform);
        return valueToReturn;
    }

    const canShowAlertNotifications = () => {
        const stateIsPredicted = currentState === VehicleMaintenanceStates.PREDICTED;

        const valueToReturn = currentState && ((!maintenance && stateIsPredicted && !isDetails) ||
            (!!maintenance?.adviseMaintenanceKilometers || !!maintenance?.adviseMaintenanceDate) ||
            stateIsPredicted ||
            (!stateIsPredicted && maintenance && maintenance.alertNotices.length > 0));
        return valueToReturn;
    }

    const getVehicle = async (id?: string | null) => {
        try {
            const _vehicle = id ? await VehiclesService.getById(id) : undefined
            setVehicle(_vehicle);

            compareKms(_vehicle);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.MAINTENANCES, `Couldn't get vehicle data`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    const compareKms = (_vehicle?: VehicleDto | undefined) => {
        if (form.watch('kilometers') && (_vehicle || vehicle) && (form.watch('kilometers') ?? 0) < (_vehicle?.currentKilometers ?? vehicle?.currentKilometers ?? 0)) {
            setInputKmsBelowCurrentVehicleKms(true);
            return;
        }
        setInputKmsBelowCurrentVehicleKms(false);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.fullHeight}>
            <div {...props} className={styles.fullHeight}>
                <Row className={styles.fullHeight} style={{ margin: 0 }}>
                    <Col xs={12} sm={12} md={12} lg={8} xl={9}>
                        <div className={styles.content}>
                            {!isModal && <div className={styles.header}>
                                <img src={IconBack} className={styles.icon} onClick={() => { if (!!maintenance) { reset(maintenance); } history.goBack() }} />
                                {titleHeader}
                                {(Boolean(maintenance) && !maintenance?.active) &&
                                    <span className={styles.messageDeleted}>{' - ' + t('maintenances.maintenance_deleted')}</span>
                                }
                            </div>}
                            <Row>
                                {!vehicleId && <Col xs={12} md={6} lg={4}>
                                    <FormItem>
                                        <Label className={styles.label}>{t('vehicle.vehicle')} {'*'}</Label>
                                        <SelectController
                                            form={form as any}
                                            isDisabled={isDetails}
                                            placeholder={t('vehicle.vehicle')}
                                            name="vehicleId"
                                            options={vehicleOptions}
                                            rules={{ required: true }}
                                            onChangeSelect={(data: SelectValueLabel) => {
                                                changeVehicle(data);
                                                getContractsByVehicle();
                                                getValues();
                                            }} />
                                        <InputError error={errors.vehicleId} />
                                    </FormItem>
                                </Col>}
                                <Col xs={12} md={6} lg={4}>
                                    <FormItem>
                                        <Label className={styles.label}>{t('common.state')} {!isDetails || isModal ? '*' : ''}</Label>
                                        <SelectController
                                            form={form}
                                            name="state"
                                            placeholder={t('common.state')}
                                            isDisabled={isDetails && !isModal}
                                            options={statesOptions}
                                            rules={{ required: true }}
                                            isClearable={true}
                                            filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                                            onChangeSelect={(data: SelectValueLabel) => {
                                                setValue('state', data ? data.value : null);
                                                setValue('maintenanceDate', null);
                                                setValue('maintenanceHour', null);
                                                setMaintenance(maintenance ? { ...maintenance, maintenanceDate: undefined, maintenanceHour: undefined } : null);
                                                setState(data?.value);
                                                getGpsKms();
                                            }}
                                        />
                                        <InputError error={errors.state} />
                                    </FormItem>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <FormItem>
                                        <Label className={styles.label}>{t('common.description')} {!isDetails || isModal ? '*' : ''}</Label>
                                        <InputController
                                            name="description"
                                            form={form as any}
                                            autoComplete='off'
                                            placeholder={t('common.description')}
                                            disabled={isDetails && !isModal}
                                            rules={{ ...DEFAULT_INPUT_RULES_WITH_REQUIRED }}
                                            defaultValue={maintenance?.description}
                                        />
                                        <InputError error={errors.description} />

                                    </FormItem>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <FormItem>
                                        <Label className={styles.label}>{t('common.type')} {!isDetails || isModal ? '*' : ''}</Label>
                                        <SelectController
                                            form={form}
                                            name="typeId"
                                            placeholder={t('common.types')}
                                            isDisabled={isDetails && !isModal}
                                            options={contractTypeOptions}
                                            rules={{ required: true }}
                                            isClearable={true}
                                            filterOption={(typeFO: any, input: any) => input ? removeAccents(typeFO.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                                            onChangeSelect={(data: SelectValueLabel) => {
                                                setValue('typeId', data ? data.value : null);
                                            }}
                                        />
                                        <InputError error={errors.typeId} />
                                    </FormItem>
                                </Col>
                                {currentState === VehicleMaintenanceStates.PREDICTED && <Col xs={12} md={6} lg={8} className={styles.colXXL}>
                                    <FormItem>
                                        <Label className={styles.label}>{t('maintenances.advise_kilometer_date')} <FaInfoCircle className={styles.buttonOptions} data-tip={t('maintenances.advise_kilometer_date_info')} /> </Label>
                                        <InputGroupDate
                                            inputText={t('common.kms')}
                                            inputPlaceholder={t('common.kilometers')}
                                            inputName="adviseMaintenanceKilometers"
                                            form={form}
                                            inputDisabled={isDetails && !isModal}
                                            inputType="number"
                                            inputFormat="money"

                                            datePlaceholder={t('common.date')}
                                            onChangeDate={(date: Date) => {
                                                setValue('adviseMaintenanceDate', date);
                                                getContactNumbersByDate(date);
                                            }}
                                            dateSelected={maintenance && maintenance.adviseMaintenanceDate ? moment(maintenance.adviseMaintenanceDate).toDate() : undefined}
                                            dateFormat={DATE_FORMAT_MONTH_TEXT_YEAR}
                                            dateCustomInput={<InputGroup icon={<FaCalendarAlt />} removeInputBorderRadius={true} />}
                                            dateName="adviseMaintenanceDate"
                                            dateRequired={false}
                                            dateDisabled={isDetails && !isModal}
                                            showMonthYearPicker
                                        />
                                        <InputError error={errors.adviseMaintenanceKilometers || errors.adviseMaintenanceDate} />
                                    </FormItem>
                                    <ReactTooltip />
                                </Col>}
                                {currentState === VehicleMaintenanceStates.PERFORM && <Col xs={12} md={6} lg={4}>
                                    <FormItem>
                                        <Label className={styles.label}>{t('common.kilometers')}</Label>
                                        <InputGroupController
                                            text={t('common.kms')}
                                            placeholder={t('common.kilometers')}
                                            name="kilometers"
                                            form={form}
                                            disabled={isDetails && !isModal}
                                            type="number"
                                            format="money"
                                            onChange={() => compareKms()}
                                        />
                                        <InputError error={errors.kilometers} />
                                        {vehicle && !isDetails && vehicle?.currentKilometers ? <InputInfoWarning isWarning={inputKmsBelowCurrentVehicleKms} message={t((inputKmsBelowCurrentVehicleKms ? 'common.inserted_kms_below_current_kms' : 'common.insert_kms_below_current_kms'), { kms: Utils.numberFormatter(vehicle?.currentKilometers ?? 0) })} /> : null}
                                        {hasReadGPSPolicy && vehicle && !isDetails && currentKmsGps != null && <InputInfoWarning isWarning={false} message={t('location.current_km', { kms: Utils.numberFormatter(currentKmsGps ?? 0) })} />}
                                    </FormItem>
                                </Col>}
                                <Col xs={12} md={6} lg={4}>
                                    <FormItem>
                                        <Label className={styles.label}>{t('common.supplier')}</Label>
                                        <SelectController
                                            form={form}
                                            name="supplierId"
                                            placeholder={t('common.supplier')}
                                            isDisabled={isDetails && !isModal}
                                            options={supplierOptions}
                                            isClearable={true}
                                            filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                                            onChangeSelect={(data: SelectValueLabel) => {
                                                setValue('supplierId', data ? data.value : null);
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <FormItem>
                                        <Label className={styles.label}>{t('maintenances.included_user_contract_number')}</Label>
                                        <SelectController
                                            form={form}
                                            name="maintenanceContractsId"
                                            placeholder={t('contracts.maintenance_contract.number')}
                                            isDisabled={isDetails && !isModal}
                                            options={contractNumbersOptions}
                                            isClearable={true}
                                            filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                                            onChangeSelect={(data: SelectValueLabel) => {
                                                setValue('maintenanceContractsId', data ? data.value : null);
                                                getVehiclesByContract();
                                                getValues();
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                {!maintenanceContractId && <>
                                    <Col xs={12} md={6} lg={4}>
                                        <FormItem>
                                            <Label className={styles.label}>{t('common.cost', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) })}{((!isDetails || isModal) && ((state == VehicleMaintenanceStates.PERFORM) || (form.getValues('state') == VehicleMaintenanceStates.PERFORM))) ? '*' : ''}</Label>
                                            <InputGroupController
                                                maxLength={255}
                                                type="number"
                                                text={t('common.euro')}
                                                placeholder={t('common.cost', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) })}
                                                name="cost"
                                                form={form}
                                                rules={{ maxLength: 255 }}
                                                disabled={isDetails && !isModal}
                                                format="money"
                                                defaultValue={maintenance?.cost || undefined} />
                                            {form.formState.isSubmitted && (!form.getValues('cost') && !form.getValues('maintenanceContractsId') && ((state == VehicleMaintenanceStates.PERFORM) || (form.getValues('state') == VehicleMaintenanceStates.PERFORM)))
                                                && <label className={styles.errorLabel}>
                                                    {t('common.errors.required')}
                                                </label>}
                                        </FormItem>
                                    </Col>
                                    <Col xs={12} md={6} lg={4} className={styles.colXXL}>
                                        <FormItem>
                                            <Label className={styles.label}>
                                                {t('maintenances.payment_date')}
                                            </Label>
                                            <DateTimePickerControllerNew
                                                form={form}
                                                placeholderText={t('common.date')}
                                                name="paymentDate"
                                                autoComplete='off'
                                                disabled={isDetails}
                                                rules={{ required: false }}
                                            />
                                            <InputError error={errors.paymentDate} />
                                        </FormItem>
                                    </Col>
                                </>}

                                {currentState === VehicleMaintenanceStates.PERFORM && <Col xs={12} md={6} lg={8} xl={6} className={styles.colXXL}>
                                    <FormItem>
                                        <Label className={styles.label}>
                                            {t('maintenances.date_maintenance')} {!isDetails || isModal ? '*' : ''}
                                        </Label>
                                        <DateWithHour
                                            form={form}
                                            datePlaceholder={t('common.date')}
                                            onChangeDate={(date: Date) => {
                                                setValue('maintenanceDate', date);
                                                //setMaintenance(maintenance ? { ...maintenance, maintenanceDate: date } : null);
                                            }}
                                            dateSelected={maintenance && maintenance.maintenanceDate ? moment(maintenance.maintenanceDate).toDate() : undefined}
                                            dateFormat={DATE_FORMAT_DEFAUT}
                                            dateCustomInput={<InputGroup icon={<FaCalendarAlt />} removeIconOrTextBorderRadius={true} />}
                                            dateName="maintenanceDate"
                                            dateRequired={true}
                                            dateDisabled={(isDetails && !isModal)}

                                            onTimeChoose={hour => { setValue('maintenanceHour', hour.formatted24) }}
                                            timePlaceholder={t('common.hour')}
                                            timeName="maintenanceHour"
                                            timeDisabled={(isDetails && !isModal)}
                                        />
                                        <InputError error={errors.maintenanceDate || errors.maintenanceHour} />
                                    </FormItem>
                                </Col>}

                                {canShowAlertNotifications() && <Col xs={12}>
                                    <div className={styles.containerSmallButton}>
                                        <Label className={styles.label}>{t('maintenances.alert_notices_over_km_and_date_expected')}</Label>
                                        {(!isDetails && currentState !== VehicleMaintenanceStates.PERFORM) && <ScreenHeaderButton icon={AddIcon} onClick={addAlertNotice} classNameIcon={styles.iconAdd} />}
                                    </div>

                                    {alertNotices.fields.length === 0 && <div className={styles.noInfoText}>
                                        {t('maintenances.without_alert_notices')}
                                    </div>}

                                    {!!alertNoticeOptions && !!showAlertNotice && !!alertNotices && alertNotices.fields.map((x, i) => {
                                        return (<Row key={x.formId} className={styles.rowAlerts}>
                                            <Col xs={4}>
                                                {renderAlertNotice(i, x)}
                                            </Col>
                                            {(!(isDetails && !isModal) && currentState !== VehicleMaintenanceStates.PERFORM) && <Col xs={1}>
                                                <TrashIcon className={styles.removeIcon} onClick={() => setRemoveAlertNotice(i)} />
                                            </Col>}
                                        </Row>)
                                    })}
                                    <hr />
                                </Col>}

                                {canShowMaintenanceQuestionArea() && <Col xs={12} style={{ marginTop: '0.5rem' }}>
                                    <Label className={`${styles.label} ${styles.labelQuestion} `}>{t('maintenances.program_date_maintenance_question')}</Label>
                                </Col>}
                                {canShowMaintenanceQuestionArea() && <Col xs={12} md={6} lg={6} xl={4}>
                                    <FormItem>
                                        <Label className={styles.label}>{t('maintenances.affected_to')}</Label>
                                        <SelectController
                                            form={form}
                                            name="responsibleId"
                                            placeholder={t('maintenances.affected_to')}
                                            isDisabled={(isDetails && !isModal) || currentState === VehicleMaintenanceStates.PERFORM}
                                            options={responsibleOptions}
                                            isClearable={true}
                                            filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                                            onChangeSelect={(data: SelectValueLabel) => {
                                                setValue('responsibleId', data ? data.value : null);
                                            }}
                                        />
                                    </FormItem>
                                </Col>}
                                {canShowMaintenanceQuestionArea() && <Col xs={12} md={6} lg={8} xl={6} className={styles.colXXL}>
                                    <FormItem>
                                        <Label className={styles.label}>
                                            {t('maintenances.appointment_date')}
                                        </Label>
                                        <DateWithHour
                                            form={form}
                                            datePlaceholder={t('common.date')}
                                            onChangeDate={(date: Date) => {
                                                setValue('expectedDate', date);
                                                //setMaintenance(maintenance ? { ...maintenance, expectedDate: date } : null);
                                            }}
                                            dateSelected={maintenance && maintenance.expectedDate ? moment(maintenance.expectedDate).toDate() : undefined}
                                            dateFormat={DATE_FORMAT_DEFAUT}
                                            dateCustomInput={<InputGroup icon={<FaCalendarAlt />} removeIconOrTextBorderRadius={true} />}
                                            dateName="expectedDate"
                                            dateRequired={false}
                                            dateDisabled={(isDetails && !isModal) || currentState === VehicleMaintenanceStates.PERFORM}
                                            onTimeChoose={hour => { setValue('expectedHour', hour.formatted24) }}
                                            timePlaceholder={t('common.hour')}
                                            timeName="expectedHour"
                                            timeDisabled={(isDetails && !isModal) || currentState === VehicleMaintenanceStates.PERFORM}
                                        />
                                        <InputError error={errors.expectedDate || errors.expectedHour} />
                                    </FormItem>
                                </Col>}


                                {canShowAlertNotifications() && <Col xs={12}>
                                    <div className={styles.containerSmallButton}>
                                        <Label className={styles.label}>{t('maintenances.alert_notices_over_appointment_date')}</Label>
                                        {(!isDetails && currentState !== VehicleMaintenanceStates.PERFORM) && <ScreenHeaderButton icon={AddIcon} onClick={addPlannedAlertNotice} classNameIcon={styles.iconAdd} />}
                                    </div>

                                    {plannedAlertNotices.fields.length === 0 && <div className={styles.noInfoText}>
                                        {t('maintenances.without_alert_notices')}
                                    </div>}

                                    {!!plannedAlertNoticeOptions && !!showPlannedAlertNotice && !!plannedAlertNotices && plannedAlertNotices.fields.map((x, i) => {
                                        return (<Row key={x.formId} className={styles.rowAlerts}>
                                            <Col xs={4}>
                                                {renderPlannedAlertNotice(i, x)}
                                            </Col>
                                            {(!(isDetails && !isModal) && currentState !== VehicleMaintenanceStates.PERFORM) && <Col xs={1}>
                                                <TrashIcon className={styles.removeIcon} onClick={() => setRemovePlannedAlertNotice(i)} />
                                            </Col>}
                                        </Row>)
                                    })}
                                    <hr />
                                </Col>}
                            </Row>
                            {renderButtons()}
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={4} xl={3}
                        className={styles.colRightContent}>
                        <div>
                            <Label className={styles.subtitle}>{t('common.attachment')}</Label>
                            <FormItem>
                                <FileSelector
                                    isDetails={isDetails && !isModal}
                                    isMulti={true}
                                    initialFiles={getValues('attachments')}
                                    onFilesChanged={setValuesFiles}
                                    onRemoveFile={onRemoveFile}
                                    label={<Label className={styles.label}>{t('common.add_new_file')}</Label>}
                                    smallButtonAdd={true}
                                    fileButtonSizes={{ sm: 12, lg: 6 }}
                                />
                            </FormItem>
                        </div>
                        <div>
                            <FormItem>
                                <Label className={styles.label}>{t('common.notes')}</Label>
                                <InputResizable
                                    name="notes"
                                    form={form as any}
                                    disabled={isDetails && !isModal}
                                    defaultValue={maintenance?.notes}
                                    onChange={event => setValue('notes', event.currentTarget.value.toString())}
                                    placeholder={t('common.notes')}
                                    rows={5}
                                />
                            </FormItem>
                        </div>
                    </Col>
                </Row>
            </div>

            <QuestionYesNo
                message={t('common.messages.remove_record')}
                isVisible={showRemoveAlertNotice}
                onYes={() => removeAlertNotice()}
                onNo={() => { setShowRemoveAlertNotice(false); setAlertNoticeToRemoveIndex(null) }}
            />

            <QuestionYesNo
                message={t('common.messages.remove_record')}
                isVisible={showRemovePlannedAlertNotice}
                onYes={() => removePlannedAlertNotice()}
                onNo={() => { setShowRemovePlannedAlertNotice(false); setPlannedAlertNoticeToRemoveIndex(null) }}
            />

        </form >
    );
}

export default MaintenanceScreen;
